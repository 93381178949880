import React from 'react'
import { Link } from 'gatsby'
import { FirebaseContext } from 'gatsby-plugin-firebase'

export default props => {
    const fb = React.useContext(FirebaseContext)
    const [submitting, setSubmitting] = React.useState(false)
    const [submitted, setSubmitted] = React.useState(false)
    const [email, setEmail] = React.useState('')

    const onEmailChange = e => setEmail(e.target.value)

    const handleSubmit = async e => {
        e.preventDefault()
        if (!fb) return
        setSubmitting(true)
        await fb.auth().sendPasswordResetEmail(email)
        setSubmitting(false)
        setSubmitted(true)
    }
    return (
        <div className="fixed inset-0 min-h-screen flex items-center justify-center bg-gray-100">
            <button onClick={() => window.history.back()} className="">
                <div className="bg-gray-100 hover:bg-gray-200 focus:bg-gray-200 rounded-full text-gray-600 fixed inset-y-0 h-10 w-10 p-2 ml-4 mt-4 left-0 flex-center">
                    <svg
                        className="h-full w-full"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M10 19l-7-7m0 0l7-7m-7 7h18"
                        />
                    </svg>
                </div>
            </button>
            <div className="max-w-md w-full px-6">
                <Link to="/" className="flex-center text-gray-900 transform duration-100 scale-100 active:scale-95">
                    <svg
                        className="mr-1 w-8 h-8"
                        viewBox="0 0 24 22"
                        width="32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0.061512 0.740892L11.5615 21.7596C11.7373 22.0801 12.2627 22.0801 12.4385 21.7596L23.9385 0.740892C24.0234 0.58597 24.0205 0.397326 23.9297 0.245335C23.8398 0.0933437 23.6767 0 23.5 0L0.499989 0C0.323231 0 0.160145 0.0933437 0.0703011 0.245335C-0.0205192 0.397326 -0.0234489 0.58597 0.061512 0.740892ZM16.9062 10.5L7.09374 10.5L12 1.54102L16.9062 10.5Z"
                            fill="currentColor"
                        />
                    </svg>
                    <span className="text-2xl font-medium leading-5">Indie Nuggets</span>
                </Link>
                {!submitted ? (
                    <>
                        <h2 className="mt-10 text-3xl font-semibold text-center leading-9 font-display">
                            Reset password
                        </h2>
                        <p className="mt-5 text-sm leading-5 text-center text-gray-600">
                            Enter your email and we'll send you a link to reset your password.
                        </p>

                        <form className="mt-5" onSubmit={handleSubmit}>
                            <div className="rounded-md shadow-sm">
                                <input
                                    aria-label="Email address"
                                    name="email"
                                    type="email"
                                    required
                                    className="border-gray-300 placeholder-gray-500 focus:shadow-outline-blue focus:border-blue-300  appearance-none relative block w-full px-3 py-2 border text-gray-900 rounded-md focus:outline-none sm:text-sm sm:leading-5"
                                    placeholder="Email address"
                                    onChange={onEmailChange}
                                    value={email}
                                />
                            </div>

                            <div className="mt-5">
                                <button
                                    type="submit"
                                    className={`${
                                        submitting
                                            ? 'opacity-50 pointer-events-none'
                                            : 'hover:bg-gray-600'
                                    } relative block w-full py-2 px-3 border border-transparent rounded-md text-white font-semibold bg-gray-800 hover:bg-gray-700 focus:bg-gray-900 focus:outline-none focus:shadow-outline sm:text-sm sm:leading-5 transform duration-100 scale-100 active:scale-95`}
                                >
                                    <span className={`${submitting ? 'opacity-0' : ''} `}>
                                        Send password reset email
                                    </span>
                                    <span
                                        className={`${
                                            submitting ? 'opacity-100' : 'opacity-0'
                                        } absolute inset-0 flex items-center justify-center pointer-events-none `}
                                    >
                                        <svg className="h-8 w-8 spin" viewBox="0 0 24 24">
                                            <path
                                                className="text-gray-600"
                                                fill="currentColor"
                                                d="M12 21a9 9 0 100-18 9 9 0 000 18zm0-2a7 7 0 110-14 7 7 0 010 14z"
                                            />
                                            <path
                                                className="text-gray-400"
                                                fill="currentColor"
                                                d="M12 3a9 9 0 010 18v-2a7 7 0 000-14V3z"
                                            />
                                        </svg>
                                    </span>
                                </button>
                            </div>
                        </form>

                        <div className="mt-6">
                            <div className="relative">
                                <div className="absolute inset-0 flex items-center">
                                    <div className="w-full border-t border-gray-300" />
                                </div>
                                <div className="relative flex justify-center text-sm leading-5">
                                    <span className="px-2 bg-gray-100 text-gray-500">
                                        Don't have an account?
                                    </span>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <h2 className="mt-10 text-xl font-semibold text-teal-600 text-center leading-9 font-display">
                        Password Reset Email Sent!
                        <br /> Please check your email
                    </h2>
                )}
                <div className="mt-6">
                    <a
                        href="/login"
                        className="block w-full text-center py-2 px-3 border border-gray-300 rounded-md text-gray-900 font-medium hover:border-gray-400 focus:outline-none focus:border-gray-400 sm:text-sm sm:leading-5 transform duration-100 scale-100 active:scale-95"
                    >
                        Go to Login Page
                    </a>
                </div>
            </div>
        </div>
    )
}
